<header class="header" v-if="showHeader" :class="{'header--mobile': userStore.mobile }">
    <Back v-if="showBack" :key="new Date()"></Back>

    <div class="header__top" v-if="!userStore.mobile">
        <div class="top container">
            <div class="top__logo">
                <a href="/" @click.prevent="clearFilters()" alt="home">
                    <img alt="logo" class="logo" :src="getStaticImages('mainlogo')" />
                </a>
            </div>
            <div class="top__numbers">
                <div class="top__numbers-number" v-for="i of numbers">
                    <span>{{ i.number }}</span>
                    <span>
                        <i :class="`fflag fflag-${i.country.toUpperCase()} ff-md`"></i> 
                        <b>{{ $filters.currency(i.tariff) }} {{ $t('header.numbers.currency') }}</b>
                    </span>
                </div>
            </div>
            <div class="top__promo">
                <div class="top__promo-bonus" v-if="marketing && fees && !marketing.current" @click="isLoggedIn() ? router.push({ name: 'payment'}) : toggleModal('login')">
                    <div class="top__discount">
                        <div class="top__discount-bonus" v-for="fee in showFees">
                            <div class="top__discount-bonus-percent">{{ Math.round(fee.percentage) }}</div>
                            <div class="top__discount-bonus-text">
                                <span> {{ $t('header.bonus.extra') }}</span>
                                <span> {{ $t('header.bonus.credits') }}</span>
                                <span> {{ $t('header.bonus.purchase') }} {{ $filters.shortCurrency(Math.round(fee.amount)) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top__promo-banner" v-if="marketing && marketing.current" @click="router.push({ name: 'promos' })">
                    <div class="top__promo-number">
                        <span>{{ $t('header.promos.' + marketing.current) }}</span>
                        <span>{{ numbers[0].number }}</span>
                    </div>
                    <div class="top__promo-cost">
                        <span>{{ $filters.currency(numbers[0].tariff) }}<i>{{ $t('header.numbers.currency') }}</i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="header__mobile" v-if="userStore.mobile">
        <div class="header__mobile-wrapper" v-show="userMenu || catMenu || filterMenu">
            <usermenu :mobile="true" :header="false" v-if="userMenu"></usermenu>
            <catmenu :mobile="true" v-if="catMenu"></catmenu>
            <filters :mobile="true" v-show="filterMenu"></filters>
        </div>
    </div>

    <div class="header__main">
        <div class="main">
            <div class="main__home">
                <router-link :to="{ name: 'grid'}" class="fa-solid fa-home"></router-link>
            </div>
            <div class="main__logo">
                <a @click="clearFilters(); closeMenus()">
                    <img alt="logo" class="logo" :src="getStaticImages('logo')" />
                </a>
            </div>
            <div class="main__search">
                <Search :mobile="false" />
            </div>
            <div class="main__user">
                <router-link class="header--link favorites" :to="{name:'favourites'}">
                    <i class="fa-solid fa-heart fa-fw"></i>
                    {{ $t('header.user.favorites') }}
                </router-link>
                <router-link class="header--link promos" :to="{name:'promos'}">
                    <i class="fa-solid fa-star fa-fw"></i>
                    {{ $t('header.btns.promos') }}
                </router-link>
                <a href="#" class="header--link" v-if="!isLoggedIn()" @click="toggleModal('login')">
                    <i class="fa-solid fa-user fa-fw"></i>
                    {{ $t('header.user.login') }}
                </a>
                <a href="#" class="btn btn-fright btn-small btn-green" v-if="!isLoggedIn()" @click="toggleModal('register')">{{ $t('header.user.register') }}</a>
                <a href="#" class="header--link account" v-if="isLoggedIn()">
                    <i class="fa-solid fa-user fa-fw"></i>
                        {{ $t('header.user.account') }}
                    <i class="arrow"></i>
                    <span class="header--dropdown">
                        <usermenu :mobile="false" :header="true"></usermenu>
                    </span>
                    <div v-if="account.totalNotifications > 0" class="main__user-notification">!</div>
                </a>               
                <router-link class="header--link" :to="{name:'payment'}" v-if="isLoggedIn()">
                    <i class="fa-solid fa-coins fa-fw"></i>
                    {{ $t('header.user.creditss') }}: <b>{{account.credits}}</b>
                </router-link>               
                <a href="#" class="header--link languages">
                    <i :class="`fflag fflag-${language?.toUpperCase()} ff-md`"></i>
                    <i class="arrow"></i>
                    <span class="header--dropdown other" v-if="languages">
                        <a href="#" v-for="(lang, index) in languages"
                            :class="{active: lang === language?.toUpperCase() }"
                            @click.prevent="setLang(lang)"
                            :key="lang"
                        >{{ lang.toUpperCase() }}</a>
                    </span>
                </a>
            </div>
            <div class="main__mobile" v-if="userStore.mobile">
                <a href="#" class="register btn btn-green" v-if="!isLoggedIn()" @click="toggleModal('register')">
                    <i class="fa-solid fa-user-plus"></i>
                </a>
                <router-link :to="{name:'favourites'}" class="user" v-if="isLoggedIn()">
                    <i class="fa-solid fa-heart"></i>
                </router-link>
                <a href="#" @click="toggleFilterMenu()" class="filter" :class="{active: filterMenu}">
                    <i class="fa-solid fa-arrow-up-short-wide"></i>
                </a>
                <a href="#" @click="clickUserMenu()" class="user" :class="{active: userMenu}">
                    <i class="fa-solid fa-user"></i>
                    <div v-if="account.totalNotifications > 0" class="main__mobile-notification">!</div>
                </a>
                <a href="#" @click="toggleCatMenu()" class="hamburger" :class="{active: catMenu}">
                    <i class="fa-solid fa-bars"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="header__bottom" v-if="!userStore.mobile">
        <div class="bottom container">
            <div class="bottom__categories">
                <catmenu :mobile="false"></catmenu>
                
            </div>
            <div class="bottom__filters">
                <div class="header--link filter">
                    <i class="fa-solid fa-list-check"></i>
                        {{ $t('header.user.filter') }}
                    <i class="arrow"></i>
                    <span class="header--dropdown large">
                        <filters :mobile="false"></filters>
                    </span>
                </div>
            </div>          
        </div>
        <div class="bottom">
            <tagmenu :mobile="false"></tagmenu>
        </div>
    </div>
   
</header>
