import { defineStore } from 'pinia';
import { clientSeen, initiateTeaser, switchMainTeaser, switchTeaser, endSession } from '@/api/session';
import notifications from '@/socket';
import { bigBlackPoster, pickOne, seconds, subtract } from '@/utils';
import { usePerformerStore } from './performer';
import { useUserStore } from './user';
import { useTeaserVideoStore } from './teaserVideo';
import { usePaymentStore } from './payment';
import { useAlertsStore } from './alerts';
import i18n from '@/translations';
import type { initiatePayload } from '@/ontology/stream';

export type Status = 'idle' | 'checking' | 'active' | 'ending' | 'ended';

interface State {
    status: Status;
    current: {
        [id: number]: {
            type: 'VOYEUR' | 'VOYEURPEEK';
            position: number;
        };
    };
    endReason: string;
    //url of an image that holds a fake image to stretch the tile
    posterUrl: string;
    history: number[];
    showReservations: boolean;
    reservations: any[];
    interval: {
        alive: any;
        rotate: any;
    };
    rotateLast: number | any;
}
export const useTeaserStore = defineStore({
    id: 'teaser',
    state: (): State => ({
        status: 'idle',
        current: {},
        history: [],
        showReservations: false,
        reservations: [],
        interval: {
            alive: -1,
            rotate: -1
        },
        rotateLast: undefined,
        endReason: '',
        posterUrl: ''
    }),
    actions: {
        initialize() {
            //what push messages should I respond to?
            //42["receivedEvent","{\"event\":\"voyeur\",\"receiverId\":\"56121261\",\"receiverType\":\"ROLE_CLIENT\",\"content\":\"%7B%22performerId%22%3Anull%2C%22clientId%22%3A56121261%2C%22type%22%3A%22RESPONSE%22%2C%22value%22%3Afalse%2C%22id%22%3A%22777dc80efe4d0fe96b3d381700915ec3%22%2C%22message%22%3A%22BROKE%22%7D\"}"]
            //42["receivedEvent","{\"event\":\"voyeur\",\"receiverId\":\"56121261\",\"receiverType\":\"ROLE_CLIENT\",\"content\":\"%7B%22performerId%22%3A17359%2C%22clientId%22%3A56121261%2C%22type%22%3A%22RESPONSE%22%2C%22value%22%3Afalse%2C%22id%22%3A%22dba5ac6af7c6afa57c8e1c0f8d5c005c%22%2C%22message%22%3A%22MAIN_ENDED%22%7D\"}"]
            notifications.subscribe('status_change', ({ sender, value, performer }) => {
                if (sender !== 'teaserVideo') {
                    return;
                }
                if (value != 'destroying') {
                    return;
                }

                //a performer was destroyed; let's replace her

                this.history.push(performer);
                this.replace(performer);
            });
            notifications.subscribe('voyeur', msg => {
                const { performerId, value, message } = msg;
                if (performerId) {
                    return;
                }
                if (value) {
                    return;
                }

                this.ended(message);
            });

            notifications.subscribe('performer_available', ({ performer }) => {
                if (!performer) {
                    throw new Error('performer has to have a value yo');
                }

                const reservation = this.reservations.indexOf(performer);
                if (reservation == -1) {
                    return;
                }

                useAlertsStore().openMessage({
                    content: i18n.global.t('teaser.alerts.reservationAvailable', { performer: usePerformerStore().getById(performer).nickname }),
                    translate: false,
                    displayTime: seconds(3),
                    class: 'info'
                });

                this.reservations.splice(reservation, 1);
            });
        },
        setStatus(newValue: Status) {
            this.status = newValue;

            notifications.sendLocalEvent('status_change', {
                sender: 'teaser',
                value: this.status
            });
        },
        async keepAlive() {
            if (this.status != 'active') {
                return;
            }

            const { error } = await clientSeen('VOYEUR');
            if (error && error.code == 401) {
                this.ended('OOPS');
            }
            //TODO: so what if another error that 401 is thrown?
        },
        async initiate(withPerformer?: number, payload?: initiatePayload) {
            if (this.status == 'ended') {
                this.setStatus('idle');
            }

            if (this.status != 'idle') {
                return;
            }

            if (withPerformer) {
                this.current[withPerformer] = { type: 'VOYEUR', position: 0 };
            }

            return await this.start(payload ? payload.ivrCode : undefined);
        },
        async start(ivrCode?: string) {
            //first check if teaser is initiated..
            const mainTeaser: any = this.getMainTeaser();
            if (mainTeaser === undefined) {
                console.error('Main teaser not intiated..');
                return;
            }

            this.setStatus('checking');

            const payment = usePaymentStore();

            //only try using an ivr code that's started already
            if (!ivrCode && payment.start) {
                ivrCode = payment.code;
            }

            payment.authorize('teaser', ivrCode ? 'ivr' : 'credits', ivrCode);

            const { error } = await initiateTeaser(useUserStore().account.id!, ivrCode);
            if (error) {
                this.setStatus('idle');

                //reset ivr code..
                if (error.code === 494 && ivrCode) {
                    payment.code = undefined;
                }

                return error;
            }

            const performers = usePerformerStore();
            await performers.loadTeasers({ offset: 0, limit: 1000 });
            performers.currentSlice = 'voyeur';
            this.setStatus('active');

            this.interval.alive = setInterval(this.keepAlive, seconds(5));

            //Load SideTiles
            const available = this.getVoyeurs(false);
            let k = available.length > 4 ? 4 : available.length;
            for (let i = 1; i < k; i++) {
                const id = this.chooseNext();
                this.current[id as any] = { type: 'VOYEURPEEK', position: i };
                await this.innitStore(id as any, 'VOYEURPEEK');
            }

            // Load mainTile
            await this.innitStore(this.getMainTeaser() as any, 'VOYEUR');

            // Start Rotate Sideiles
            this.interval.rotate = setInterval(async () => {
                this.randomRotate();
            }, seconds(8));
        },
        randomRotate() {
            let list: any = JSON.parse(JSON.stringify(this.current));
            if ((!this.getMainTeaser() as any) || this.getVoyeurs(true).length < 4) {
                return;
            }

            delete list[this.getMainTeaser() as any]; // Remove main Tile
            delete list[this.rotateLast]; // Remove last changed Tile

            const randomTile: any = Object.keys(list)[Math.floor(Math.random() * 2)];
            this.rotateLast = randomTile;
            this.replace(randomTile);
        },
        chooseNext(stack = 1): number | boolean {
            let choices = this.getVoyeurs(true);

            if (choices.length == 0) {
                //return false;
                if (stack == 2) {
                    return false;
                } else {
                    //just reset the history and try again
                    this.history = [];
                    return this.chooseNext(stack + 1);
                }
            } else {
                // Pick random one
                return pickOne(choices);
            }
        },
        async replace(performer: number) {
            const position = this.current[performer];
            if (!position) {
                //scenario where message of the tease video being destroyed arrives after the status of teaser is already "idle"
                return;
            }

            if (this.status != 'active') {
                return;
            }

            const next = this.chooseNext();
            if (!next) {
                return;
            }

            // If main Teaser replace, End old and send switchCall
            if (position.type === 'VOYEUR') {
                switchMainTeaser(next as any);
            }

            useTeaserVideoStore(performer).end();
            delete this.current[performer];

            this.current[next as any] = position;
            await this.innitStore(next as any, position.type);
        },
        async innitStore(id: number, type: any) {
            const videoStore = useTeaserVideoStore(id as any);
            const error = await videoStore.initiate(type);
            if (error) {
                videoStore.destroy();
            }
        },
        switchTo(performer: number) {
            switchTeaser(performer);

            if (!this.current[performer]) {
                //OMG the performer disappeared while switching..
                return;
            }
            const oldId = this.getMainTeaser();
            if (oldId) {
                this.toggleSwitching(true, oldId);
                this.current[oldId] = { type: 'VOYEURPEEK', position: this.current[performer].position };
                useTeaserVideoStore(oldId).type = 'VOYEURPEEK';
                this.toggleSwitching(false, oldId);
            }

            this.toggleSwitching(true, performer);
            this.current[performer] = { type: 'VOYEUR', position: 0 };
            useTeaserVideoStore(performer).type = 'VOYEUR';
            this.toggleSwitching(false, performer);
        },
        ended(reason: string) {
            this.endReason = reason;
            this.setStatus('ended');

            useAlertsStore().openMessage({
                content: `profile.session.endreason.${this.endReason}`,
                translate: true,
                displayTime: seconds(1),
                class: 'error'
            });
        },
        end(toIdle: boolean = false) {
            //the user wants to end this whole teaser session
            if (['ending', 'ended'].includes(this.status)) {
                if (toIdle) {
                    this.setStatus('idle');
                }
                return;
            }
            this.setStatus('ending');
            endSession({
                clientId: useUserStore().account.id!,
                type: 'VOYEURCLIENT'
            });
            this.setStatus('ended');
            this.reset();

            if (toIdle) {
                this.setStatus('idle');
            }
        },
        toggleSwitching(status: boolean, id: number) {
            if (status) {
                useTeaserVideoStore(id).switching = true;
            } else {
                setTimeout(() => {
                    useTeaserVideoStore(id).switching = false;
                }, seconds(0.5));
            }
        },
        toggleReservation(performer: number) {
            const reservation = this.reservations.indexOf(performer);
            if (reservation > -1) {
                this.reservations.splice(reservation, 1);
                this.showReservations = false;
            } else {
                useAlertsStore().openMessage({
                    content: i18n.global.t('teaser.alerts.reservationSuccess', { performer: usePerformerStore().getById(performer).nickname }),
                    translate: false,
                    displayTime: seconds(1),
                    class: 'success'
                });
                this.reservations.push(performer);
                this.showReservations = true;
            }
        },
        reset() {
            this.current = {};
            this.reservations = [];
            this.history = [];
            this.showReservations = false;

            clearInterval(this.interval.rotate);
            clearInterval(this.interval.alive);
        },
        resetHistory() {
            this.history = [];
        },
        toggleShowReservations() {
            this.showReservations = !this.showReservations;
        },
        isReserved(performer: number) {
            return this.reservations.indexOf(performer) > -1;
        },
        getVoyeurs(history: boolean) {
            const available = usePerformerStore().getSlice('voyeur').items;
            const exclude = this.history.concat(Object.keys(this.current).map(id => parseInt(id)));
            let choices = subtract(exclude, available);
            return history ? choices : available;
        },
        getMainTeaser() {
            for (let id in this.current) {
                if (this.current[id].type == 'VOYEUR') {
                    return parseInt(id);
                }
            }

            return undefined;
        }
    }
});
