import { getToyInfo, initiateToyQuantity, initiateToyRule } from '@/api/session';
import notifications from '@/socket';
import { seconds } from '@/utils';
import { defineStore } from 'pinia';
import { useAlertsStore } from './alerts';
import { usePerformerStore } from './performer';
import { useUserStore } from './user';
import { useCamStore } from './cam';
import i18n from './../translations';
import type { LovenseRule, LovenseSpecialRule, ToyQueueItem, ToySocketMessage } from '@/ontology/toy';
import type { User } from '@/ontology/user';
import { useChatStore } from './chat';

interface State {
    //what command did the user send last?
    lastUse: 0 | 1 | 2 | 4 | number;
    timeLeft: number;
    duration: number;
    interval: any;
    queue: ToyQueueItem[];
    queueRunning: boolean;
    queueTimer: any;
    lastItem?: ToyQueueItem;
    rules?: LovenseRule[];
    specialRules?: LovenseSpecialRule[];
}

const SEC_IN_MS = 1000;

export const useToyStore = defineStore({
    id: 'toy',
    state: (): State => ({
        //what is the latest command the user sent, that is still active?
        lastUse: 0,
        timeLeft: 0,
        duration: 0,
        queue: [],
        queueRunning: false,
        queueTimer: undefined,
        lastItem: undefined,
        interval: undefined,
        rules: undefined,
        specialRules: undefined
    }),
    getters: {
        userId() {
            const userId = useUserStore().account!.id;
            if (!userId) {
                throw new Error("can't try to use a toy if there's no userid");
            }
            return userId;
        },
        advertNumber() {
            const { performer } = useCamStore();

            const advert = usePerformerStore().getById(performer).advertNumber;
            if (!advert) {
                throw new Error("can't try to use a toy without a session with a performer");
            }
            return advert;
        },
        totalSeconds(state: State) {
            let sum: number = 0;
            state.queue.forEach(a => (sum += a.duration));
            return sum + state.timeLeft;
        }
    },
    actions: {
        initialize() {
            notifications.subscribe('toy', this.handleNotification);
            notifications.subscribe('toy_update', this.handleToyUpdateNotification);
        },
        startTimer(duration: number, quantity: number) {
            this.$patch({
                timeLeft: duration
            });
            //this.timeLeft = duration;
            setTimeout(() => (this.lastUse = quantity), 10);

            if (!this.interval) {
                this.interval = setInterval(this.countDown, seconds(1));
            }
        },
        resetTimer() {
            if (this.interval) {
                clearInterval(this.interval);
            }

            this.$patch({
                duration: 0,
                timeLeft: 0,
                lastUse: 0,
                interval: undefined
            });
        },
        addToQueue(item: ToyQueueItem) {
            this.queue.push(item);
            //patch it ?
            this.$patch({
                queue: this.queue
            });
        },
        clearQueue() {
            this.$patch({
                queue: [],
                queueRunning: false,
                lastItem: undefined
            });

            this.resetTimer();
            //TODO remove toy messages ?
        },
        runQueue() {
            if (this.queueRunning) {
                return;
            }

            if (this.queue.length === 0) {
                this.queueRunning = false;
                clearTimeout(this.queueTimer);
                this.queueTimer = undefined;
            }

            const item = this.queue.shift();

            if (item) {
                this.queueRunning = true;
                this.lastItem = item;

                if (item.initiatedByMe) {
                    //go loader

                    this.startTimer(item.duration, item.position);
                }

                this.queueTimer = setTimeout(() => {
                    this.queueRunning = false;
                    this.runQueue();
                }, item.duration * SEC_IN_MS);
            } else {
                //clear queue ?
                this.clearQueue();
                //this.lastItem = undefined;
                //this.queueRunning = false;
            }
        },
        handleToyUpdateNotification({ performerId, type, value }: any) {
            this.getToyInfo();
        },
        handleNotification({ initiator, value, service, special, rule }: ToySocketMessage) {
            const { type, performer } = useCamStore();

            if (!performer) {
                return;
            }

            const { account } = useUserStore();
            const initiatedByMe = initiator === true;
            const sessionType = type;
            const performerName = usePerformerStore().getById(performer)?.nickname;
            const ownName = account.username;
            const username = service === 'PEEK' ? (initiatedByMe ? ownName : 'Peek') : initiatedByMe ? ownName : 'Main';

            //get rule entity
            let ruleEntity = undefined;
            if (rule) {
                ruleEntity = this.getRule(+rule, special === 'true');
            }

            const duration = ruleEntity ? ruleEntity.reactionTime : value * 5;

            if (initiatedByMe) {
                this.addToQueue({
                    duration: duration,
                    performerName: performerName,
                    initiatedByMe: initiatedByMe,
                    userName: username,
                    service: service,
                    position: +value,
                    rule: ruleEntity
                });

                this.queueTimer = setTimeout(() => {
                    this.runQueue();
                }, 100);

                //messaging..
            }

            if (sessionType !== 'peek') {
                //only show messages initiated by me..
                if (initiatedByMe) {
                    this.addToChat(performerName, initiator, service, duration);
                    this.openMessage(performerName, initiator, service, duration);
                }
            } else {
                //show message if its a peeker...
                this.openMessage(performerName, initiator, service, duration);
            }
        },
        async useToy(quantity: 1 | 2 | 4) {
            const duration = quantity * 5;
            const userId = this.userId;
            const advert = this.advertNumber;

            const { error } = await initiateToyQuantity(userId, advert, quantity);

            if (error) {
                useAlertsStore().openMessage({ content: error.message, class: 'error' });
                return;
            }
        },
        async useToyRule(rule: number, special: boolean, itemId?: number) {
            let duration = 0;
            let quantity = 1;
            const userId = this.userId;
            const advert = this.advertNumber;

            //using toy rule
            if (special && this.specialRules) {
                const ruleEntity = this.specialRules.find((p: any) => p.id === +rule);
                if (ruleEntity) {
                    duration = ruleEntity.reactionTime;
                    quantity = ruleEntity.amount;
                }
            } else if (this.rules) {
                const ruleEntity = this.rules.find((p: any) => p.id === +rule);
                if (ruleEntity) {
                    duration = ruleEntity.reactionTime;
                    quantity = ruleEntity.amount;
                }
            } else {
                //else rule not found
                console.error(`Rule ${rule} not found`);
                return undefined;
            }

            const { result, error } = await initiateToyRule(userId, advert, quantity, rule, special, itemId);

            if (error) {
                useAlertsStore().openMessage({ content: error.message, class: 'error' });
                return undefined;
            }

            /*this.timeLeft += duration; //queue needs to be implemented...
            this.lastUse = 0;
            //make sure the lastUse is toggled for the css animation to work properly
            setTimeout(() => (this.lastUse = quantity), 10);

            if (!this.interval) {
                this.interval = setInterval(this.countDown, seconds(1));
            }*/

            return duration;
        },
        countDown() {
            this.timeLeft--;
            if (this.timeLeft <= 0) {
                /*clearInterval(this.interval);
                this.$patch({
                    interval: undefined,
                    lastUse: 0,
                    timeLeft: 0
                });*/
                this.resetTimer();
            }
        },
        async getToyInfo() {
            const { performer } = useCamStore();

            if (!performer) {
                throw new Error('No performer selected');
            }

            const advert = usePerformerStore().getById(performer)?.advertNumber;
            if (!advert) {
                //throw new Error('can not try to use a toy without a session with a performer');
                return;
            }

            const { error, result } = await getToyInfo(advert);

            if (error) {
                //we got error
                console.error(error);
                this.rules = undefined;
                this.specialRules = undefined;
                return;
            }

            if (result && result.rules) {
                const rules = result.rules;

                if (rules.hasRules) {
                    this.rules = rules.rules;
                } else {
                    this.rules = undefined;
                }

                if (rules.hasSpecial) {
                    this.specialRules = rules.specialRules;
                } else {
                    this.specialRules = undefined;
                }
            } else {
                this.rules = undefined;
                this.specialRules = undefined;
            }
        },
        getRule(ruleId: number, special: boolean = false) {
            let ruleEntity = undefined;
            if (ruleId) {
                if (special === true) {
                    if (this.specialRules) {
                        ruleEntity = this.specialRules.find((p: any) => p.id === ruleId);
                    }
                } else {
                    if (this.rules) {
                        ruleEntity = this.rules.find((p: any) => p.id === ruleId);
                    }
                }
            }
            return ruleEntity;
        },
        addToChat(performer: string, initiator: boolean, service: string, sec: number) {
            let message = 'profile.session.toy.';

            if (initiator && service == 'PEEK') {
                message += 'you.' + (Math.floor(Math.random() * 4) + 1);
            } else if (initiator && ['VIDEO', 'VIDEOCALL', 'VIPVIDEO'].includes(service)) {
                message += 'you.' + (Math.floor(Math.random() * 5) + 1);
            } else if (!initiator && ['VIDEO', 'VIDEOCALL', 'VIPVIDEO'].includes(service)) {
                message += 'teaser';
            } else if (!initiator && service == 'PEEK') {
                message += 'peek.' + (Math.floor(Math.random() * 5) + 1);
            } else {
                //this should off course not happen
                throw new Error(`toystore: what service is ${service} anyway?`);
            }

            if (service === 'PEEK') {
                //console.log('TODO Add peek to chatbox', sec);
                useChatStore().handleMessage({ senderType: 'ROLE_TOY', message: i18n.global.t(message, { performer, sec }) });
            } else {
                //console.log('TODO Add main to chatbox', sec);
                useChatStore().handleMessage({ senderType: 'ROLE_TOY', message: i18n.global.t(message, { performer, sec }) });
            }
        },
        openMessage(performer: string, initiator: boolean, service: string, sec: number) {
            let message = 'profile.session.toy.';
            //const sec = value * 5;

            if (initiator && service == 'PEEK') {
                message += 'you.' + (Math.floor(Math.random() * 4) + 1);
            } else if (initiator && ['VIDEO', 'VIDEOCALL', 'VIPVIDEO'].includes(service)) {
                message += 'you.' + (Math.floor(Math.random() * 5) + 1);
            } else if (!initiator && ['VIDEO', 'VIDEOCALL', 'VIPVIDEO'].includes(service)) {
                message += 'teaser';
            } else if (!initiator && service == 'PEEK') {
                message += 'peek.' + (Math.floor(Math.random() * 5) + 1);
            } else {
                //this should off course not happen
                throw new Error(`toystore: what service is ${service} anyway?`);
            }

            //TODO: pimp that success message for peek
            useAlertsStore().toyMessage(i18n.global.t(message, { performer, sec }));
        }
    }
});
