import { defineStore } from 'pinia';
import router from '@/router';
import { useLocalizationStore } from './localization';

export interface AgeFilter {
    title: number;
    min: number;
    max: number;
    enabled: boolean;
}
export type ServiceType = 'voyeur' | 'peek' | 'cam' | 'phone' | 'videocall' | 'toy';

export interface ServiceFilter {
    name: ServiceType | string;
}

interface State {
    allServices: ServiceType[] | any;
    serviceFilters: ServiceFilter[];
    ageFilters: AgeFilter[];
}

export const useFilterStore = defineStore({
    id: 'filter',
    state: (): State => ({
        allServices: [],
        serviceFilters: JSON.parse(localStorage.getItem('serviceFilters') || '[]'),
        ageFilters: JSON.parse(localStorage.getItem('ageFilters') || JSON.stringify([
            { title: 1, min: 18, max: 22, enabled: false },
            { title: 2, min: 22, max: 30, enabled: false },
            { title: 3, min: 30, max: 40, enabled: false },
            { title: 4, min: 40, max: 80, enabled: false }
        ]))
    }),
    getters: {
        availableServices: () => {
            const localizationStore = useLocalizationStore();
            return localizationStore.FilterServices;
        },    
        minAge(state: State) {
            const minAges = state.ageFilters.filter(p => p.enabled).map(age => age.min);
            let minValue = Math.min(...minAges);
            return minValue === Infinity ? undefined : Math.min(...minAges);
        },
        maxAge(state: State) {
            const maxAges = state.ageFilters.filter(p => p.enabled).map(age => age.max);
            let maxValue = Math.max(...maxAges);
            return maxValue === Infinity ? undefined : Math.max(...maxAges);
        }
    },
    actions: {
        async initialize() {
            await router.isReady();
            
            const localizeStore = useLocalizationStore();
            this.allServices = localizeStore.FilterServices;
            
            const currentRoute = router.currentRoute.value;
            const { query } = router.currentRoute.value;

            if (query.services) {
                const filters = (query.services as string).split(',');
                filters.forEach(filter => this.addServiceFilter({ name: filter as ServiceType }));
            }

            if (query.ages) {
                const [minAge, maxAge] = (query.ages as string).split(';').map(Number);
                if (minAge && maxAge) {
                    this.ageFilters.forEach(filter => {
                        if (filter.min >= minAge && filter.max <= maxAge) {
                            filter.enabled = true;
                        }
                    });
                }
            }
        },
        async clearFilters() {
            this.ageFilters.forEach(filter => filter.enabled = false);
            this.serviceFilters = [];
            this.saveFiltersToLocalStorage();

            await router.push({
                name: 'grid'
            });
        },
        async applyFilter() {
            const currentRoute = router.currentRoute.value;
            
            const query = this.getFilterQuery();
            this.saveFiltersToLocalStorage();

            await router.push({
                name: 'grid',
                params: { category: currentRoute.params.category },
                query: { ...query }
            });
        },
        getFilterQuery() {
            const query: any = {};
            const services = this.serviceFilters.map(p => p.name).toString();
            if (services && services != '') {
                query.services = services;
            }
            const ages = this.maxAge === undefined || this.minAge === undefined ? undefined : `${this.minAge};${this.maxAge}`;
            if (ages) {
                query.ages = ages;
            }

            return query;
        },
        addServiceFilter(filter: ServiceFilter): ServiceFilter {
            const serviceFilter = this.serviceFilters.find(p => p.name == filter.name);
            if (serviceFilter) {
                return serviceFilter;
            }

            this.serviceFilters.push(filter);
            this.saveFiltersToLocalStorage();

            return filter;
        },
        isServiceFilterActive(service: ServiceType) {
            const serviceFilter = this.serviceFilters.find(p => p.name == service);
            return serviceFilter !== undefined;
        },
        removeServiceFilter(filter: ServiceFilter) {
            this.serviceFilters = this.serviceFilters.filter(p => p.name != filter.name);
            this.saveFiltersToLocalStorage();
            return filter;
        },
        toggleServiceFilter(filter: ServiceFilter) {
            const serviceFilter = this.serviceFilters.find(p => p.name == filter.name);
            if (serviceFilter) {
                this.removeServiceFilter(serviceFilter);
            } else {
                this.addServiceFilter(filter);
            }
            this.applyFilter();
        },
        toggleAgeFilter(filter: AgeFilter) {
            const ageFilter = this.ageFilters.find(p => p.title === filter.title);
            if (ageFilter) {
                ageFilter.enabled = !ageFilter.enabled;
            }
            this.applyFilter();
        },
        saveFiltersToLocalStorage() {
            localStorage.setItem('serviceFilters', JSON.stringify(this.serviceFilters));
            localStorage.setItem('ageFilters', JSON.stringify(this.ageFilters));
        }
    }
});
