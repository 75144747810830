<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import router from './../../../router';
import { useUserStore } from '@/stores/user';

let searchInput = ref('');
const route = useRoute();
const userStore = useUserStore();

const props = defineProps<{
    mobile: boolean;
}>();

function search() {
    // TODO: search favorites
    router.push({
        name: 'grid',
        params: { category: route.params.category },
        query: { search: searchInput.value }
    });
    searchInput.value = '';

    if (props.mobile) {
        useUserStore().toggleCatMenu();
    }
}
</script>

<template>
    <div class="search">
        <form class="search__form" @submit.prevent="search" :class="{'mobile': userStore.mobile}">
            <input v-model="searchInput" type="text" name="search-field" :placeholder="$t('header.search.placeholder')" />
            <button type="submit" name="search-button" :class="{'disabled': searchInput.length <= 0}" aria-label="Search Button">
                <i class="fa-solid fa-magnifying-glass"></i>
                <span>{{ $t('header.search.button') }}</span>
            </button>
        </form>
    </div>
</template>

<style lang="scss">
@import './search.scss';
</style>
