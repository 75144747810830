<script lang="ts" setup>
import { watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Search from './../search/search.vue';
import { useUserStore } from '@/stores/user';
import { useModalsStore } from '@/stores/modals';
import { storeToRefs } from 'pinia';

const { toggleUserMenu, logout } = useUserStore();
const { account } = storeToRefs(useUserStore());
const { toggleModal } = useModalsStore();
const route = useRoute();
const router = useRouter();

const props = defineProps<{
    mobile: boolean;
    header: boolean;
    small?: boolean;
}>();

async function Logout() {
    const error = await logout();

    if (error) {
        // TODO: show toaster that error failed
        return;
    }

    if (props.mobile) {
        toggleUserMenu();
    }
}

const chatNotifications = computed(() => {
    return account.value.totalNotifications ? account.value.totalNotifications % 1000 : 0;
});

const totalNotifications = computed(() => {
    return account.value.totalNotifications % 1000 +  Math.floor(account.value.totalNotifications / 1000);
});

const newsNotifications = computed(() =>{
    return account.value.totalNotifications ? Math.floor(account.value.totalNotifications / 1000) : 0;
});

watch(
    () => route.fullPath,
    async () => {
        if (props.mobile) {
            toggleUserMenu();
        }
    }
);
</script>

<template src="./usermenu.html"></template>

<style lang="scss">
@import './usermenu.scss';
</style>
