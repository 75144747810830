<script setup="" lang="ts">
import { computed, onMounted, onBeforeUnmount, defineAsyncComponent } from 'vue';
import { RouterView } from 'vue-router';
import Header from './components/header/header.vue';

import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useModalsStore } from '@/stores/modals';
import { useCamStore } from '@/stores/cam';
import { storeToRefs } from 'pinia';
import { asInt } from '@/utils';

const Footer = defineAsyncComponent(() => import('@/components/footer/footer.vue'));
const Modal = defineAsyncComponent(() => import('@/components/modal/modal.vue'));
const Alerts = defineAsyncComponent(() => import('@/components/alerts/alerts.vue'));

const { hasActiveModals } = storeToRefs(useModalsStore());
const camStore = useCamStore();
const userStore = useUserStore();
const route = useRoute();

// Detech window resize + orient
onMounted(() => {
    userStore.resizeBrowserHandler(window);
    window.addEventListener('resize', userStore.resizeBrowserHandler);
    window.addEventListener('orientationchange', userStore.resizeBrowserHandler);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', userStore.resizeBrowserHandler);
    window.removeEventListener('orientationchange', userStore.resizeBrowserHandler);
});

const fixed = computed(() => {
    return (camStore.status === 'active' || hasActiveModals.value);
});

const activeModals = computed(() => {
    return hasActiveModals.value;
})

const hideHeaderTabletLandscape = computed(() => {
    return (camStore.status === 'active' && userStore.isTabletLandscape());
});

const showHeaderFooter = computed(() => {
    const pages = ['videochat', 'teaser'];
    return pages.includes(route.name as string);
});
</script>

<template>
    <Alerts />
    <Modal />
    <div class="wrapper" :class="{'fixed': fixed, 'mobile': userStore.mobile, 'greyed': activeModals }">
        <Header 
            v-if="!showHeaderFooter" 
            :page="asInt(route.query.page) || 1" 
            :limit="asInt(route.query.limit) || 39" 
            :offset="asInt(route.query.offset) || 0"
            :category="route.params.category as string || undefined"
            :search="route.query.search as string|| undefined"
            :services="route.query.services as string ||undefined"
            :ages="route.query.ages as string || undefined"
            ></Header>
        <RouterView :class="{'page--mobile': userStore.mobile }" />
        <Footer v-if="!showHeaderFooter"></Footer>
    </div>
</template>
