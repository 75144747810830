<script lang="ts" setup>
import { ref, onBeforeMount } from 'vue';
import config from './../../../config';

const back = ref(false);
const name = `${config.StorageKey}.oldSite`;

onBeforeMount( ()=>{
    load();
});

function load() {
    const item = window.sessionStorage.getItem(name);
    if(item){
        back.value = JSON.parse(item);
    } else {
        back.value = true;
        window.sessionStorage.setItem(name, 'true');
    }
}

function close() {
    back.value = false;
    window.sessionStorage.setItem(name, 'false');
}

function makeBetaUnsticky(){
    document.cookie = '_th_beta=0; domain=.thuis.nl; path=/;'
}

</script>

<template src="./back.html"></template>

<style lang="scss">
@import './back.scss';
</style>
