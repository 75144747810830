
export function useMatomo() {

    const matomoBaseUrl = "//analytics.sensemakers.nl/";
    const siteId = "3";
    
    const initMatomo = () => {
        if (!matomoBaseUrl || !siteId) {
            console.warn('[Matomo] Configuration is incomplete.');
            return;
        }

        window._paq = window._paq || [];
        window._paq.push(['setTrackerUrl', `${matomoBaseUrl}matomo.php`]);
        window._paq.push(['setSiteId', siteId]);
        window._paq.push(['enableLinkTracking']);
        window._paq.push(['enableHeartBeatTimer', 5]);

        const script = document.createElement('script');
        script.async = true;
        script.defer = true
        script.src = `${matomoBaseUrl}matomo.js`;
        script.onerror = () => {
            console.warn('[Matomo] Failed to load the Matomo script.')
        }

        const head = document.head || document.getElementsByTagName('head')[0]
        head.appendChild(script);
    }

    const trackPageView = () => {
        if (!window._paq) {
            window._paq = [];
        }

        window._paq.push(['setCustomUrl', window.location.href]);
        window._paq.push(['setDocumentTitle', document.title]);
        window._paq.push(['trackPageView']);
      };

    const trackEvent = (category: string, action: string, name?: string, value?: number) => {
        if (!window._paq) {
            window._paq = [];
        }

        const eventParams: [string, string, string, string?, number?] = ['trackEvent', category, action];
        if (name) {
            eventParams.push(name);
            if (value !== undefined) {
                eventParams.push(value);
            }
        }

        window._paq.push(eventParams);
    }

    const trackGoal = (goalId: number, customRevenue?: number) => {
        if (!window._paq) {
            window._paq = [];
        }

        window._paq.push(['trackGoal', goalId, customRevenue]);
    }

    return { initMatomo, trackPageView, trackEvent, trackGoal };
}