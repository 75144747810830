export enum StartupErrors {
    no_code = 492,
    broke,
    wrong_ivr_code,
    double_session,
    client_not_found,
    performer_not_found,
    blocked,
    not_available_yet
}

export function code(message:string){
    const apiErrors: any = {
        'Performer ist noch nicht verfügbar': StartupErrors.not_available_yet,
        'Performer is not available yet':  StartupErrors.not_available_yet,
        'Performer nog niet beschikbaar': StartupErrors.not_available_yet,

        'Die Dame hat dich blockiert': StartupErrors.blocked,
        'The performer has blocked you': StartupErrors.blocked,
        'De dame heeft je geblokkeerd': StartupErrors.blocked,

        'Performer nicht gefunden': StartupErrors.performer_not_found,
        'Performer not found': StartupErrors.performer_not_found,
        'Performer niet gevonden': StartupErrors.performer_not_found,

        'Client nicht gefunden': StartupErrors.client_not_found,
        'Client not found': StartupErrors.client_not_found,
        'Client niet gevonden': StartupErrors.client_not_found,

        'Sie haben bereits eine aktive Sitzung': StartupErrors.double_session,
        'You already have a active session': StartupErrors.double_session,
        'U heeft al een actieve sessie': StartupErrors.double_session,

        'Ungültiger Zugangscode!': StartupErrors.wrong_ivr_code,
        'Invalid payment code!': StartupErrors.wrong_ivr_code,
        'Ongeldige betaalcode!': StartupErrors.wrong_ivr_code,

        'Nicht genügend Guthaben': StartupErrors.broke,
        'Insufficient credits': StartupErrors.broke,
        'Onvoldoende credits': StartupErrors.broke
    }

    return apiErrors[message] || 500;
}