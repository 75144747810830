import type { Info } from '../../ontology/info';
import { get } from '../fetch-extension';

// fetches client accounts information
export async function getInfo() {
    return get<Info>('client/client_accounts/info');
}

export async function getTimeFromServer() {
    return get<any>('client/client_accounts/time');
}
