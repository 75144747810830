import { seconds } from '@/utils';
import { defineStore } from 'pinia';
import i18n from './../translations';

export interface AlertsState {
    messages: Message[];
}

type MessageClass = 'info' | 'success' | 'error' | 'performer' | 'toy';

export interface MessagePayload {
    content: string | object;
    translate?: boolean;
    displayTime?: number;
    class?: string;
}

export interface Message {
    id: number;
    content: string | object;
    displayTime: number;
    class: string;
    opacity: number;
}

const defaultMessageTime: number = 5000;
const maxMessages: number = 3;

export const useAlertsStore = defineStore('alerts', {
    state: (): Partial<AlertsState> => ({
        messages: []
    }),
    actions: {
        openMessage(payload: MessagePayload) {
            const useTrans = payload.translate === undefined || payload.translate === true;
            const content = useTrans ? i18n.global.t(payload.content as string) : payload.content;

            const message: Message = Object.assign({
                id: Date.now(),
                displayTime: payload.displayTime ? payload.displayTime : defaultMessageTime,
                class: payload.class ? payload.class : 'info',
                content: content,
                opacity: 1
            });

            //get last message
            const lastMessage = this.messages![this.messages!.length - 1];
            let timeToAdd = 0;
            if (lastMessage) {
                timeToAdd = new Date().valueOf() - lastMessage.id;
            }

            //Add the message to the list
            this.addMessage(message);

            //Remove message after X amount of time
            setTimeout(() => {
                this.removeMessage(message);
            }, message.displayTime + timeToAdd);
        },
        addMessage(message: Message) {
            if (this.messages!.length >= maxMessages) {
                this.messages!.shift();
            }

            this.messages!.push(message);
            let opacity = 1;

            for (const message of this.messages!.reverse()) {
                message.opacity = opacity;
                opacity = opacity - 1 / maxMessages;
            }
        },
        removeMessage(message: Message | number) {
            const id = typeof message === 'number' ? message : message.id;

            this.messages = this.messages!.filter(m => m.id !== id);

            setTimeout(() => {
                let opacity = 1;
                for (const message of this.messages!.reverse()) {
                    message.opacity = opacity;
                    opacity = opacity - 1 / maxMessages;
                }
            }, 400);
        },
        successMessage(message: string, time?: number) {
            this.openMessage({
                content: message,
                displayTime: time ? seconds(time) : seconds(5),
                class: 'success'
            });
        },
        errorMessage(message: string, time?: number) {
            this.openMessage({
                content: message,
                displayTime: time ? seconds(time) : seconds(5),
                class: 'error'
            });
        },
        toyMessage(message: string) {
            this.openMessage({
                content: message,
                translate: false,
                displayTime: seconds(3),
                class: 'toy'
            });
        },
        performerMessage(message: object) {
            this.openMessage({
                content: message,
                translate: false,
                displayTime: seconds(5),
                class: 'performer'
            });
        }
    }
});
