import type { Performer } from '@/ontology/performer';
import type { User } from '@/ontology/user';
import { delEte, get, post } from '../fetch-extension';
import { transformPerformers, type Performers } from '../performer/utils';
import type { Response, Paged } from '../response';

// Retrieve a list of the unsubscriptions from performers of a consumer
export async function getBlocks(userId: number, query: { offset: number; limit: number }): Promise<Response<Paged<Performer>>> {
    const response = await get<Performers>(`client/client_accounts/${userId}/unsubscriptions`, { query });
    return transformPerformers(response, query.offset);
}

// Adds unsubscription to a performer
export async function addBlock(userId: number, performerId: number) {
    return post<User>(`client/client_accounts/${userId}/unsubscriptions/${performerId}`);
}

// Removes a performer from unsubscriptions list
export async function unBlock(userId: number, performerId: number) {
    return delEte<User>(`client/client_accounts/${userId}/unsubscriptions/${performerId}`);
}

