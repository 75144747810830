import { defineStore } from 'pinia';
import type { Info } from '@/ontology/info';
import type { Language } from '@/ontology/i18n';
import { getInfo } from '@/api/client/client_accounts.info';
import localize from '@/api/localize';
import config from '@/config';
import loc from '@/api/loc';
import i18n from '@/translations';

interface State extends Info {
    loading: boolean;
    country: string;
    services: any[];
    //the real location (country) of the user
    location: string;
}

export const useLocalizationStore = defineStore('localization', {
    //I defined the State as Partial Info so I didn't have to default all the Info properties
    state: (): Partial<State> => ({
        loading: false,
        categories: [],
        ivr1: undefined,
        ivr2: undefined,
        countries: [],
        country: undefined,
        services: undefined,
        location: undefined,
        credits_status: undefined,
        credits_per_email: undefined,
        credits_per_minute: undefined,
        credits_per_sms: undefined,
        credits_per_toy_5s: undefined,
        language: undefined,
        languages: [],
        mail_cpm: undefined,
        mail_enabled: undefined,
        marketing: undefined,
        tags: []
    }),

    actions: {
        async initialize(language: Language) {
            if (this.loading) {
                return;
            }

            await this.setLanguage(language);
            await this.setLocation();
        },
        async setLanguage(language: Language = 'en') {
            if (this.loading) {
                return;
            }

            if (!i18n.global.availableLocales.includes(language)) {
                throw new Error(`${language} not in ${i18n.global.availableLocales}`);
            }

            this.loading = true;
            //the result is ignored! Basically, it's just a way to
            //tell the server the locale we'd like to use, to prepare for the upcoming info call
            const { error: e10n, result: location } = await localize(language);

            //TODO: we could do these requests simultaneouslyWE
            const { error: eInfo, result: info } = await getInfo();

            // Gather language, country & services
            const lang = location ? location.language : info ? info.language : config.DefaultLanguage;
            const loc = location ? location.country : info ? info.country : config.Country;
            const services = location ? location.services : ['cam', 'peek', 'toy', 'email'];

            this.$patch({
                ...info,
                ...{
                    loading: false,
                    language: lang as any,
                    country: loc,
                    services: services
                }
            });

            // Set application language
            (i18n.global.locale as any).value = lang;
        },
        async setLocation() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            const { error: eloc, result: location } = await loc();
            if (eloc) {
                //TODO: error handling bitch
                return;
            }

            // Set calculated geo location user or default
            const country = location!.country_code ? location!.country_code : config.Country;
            this.$patch({
                loading: false,
                location: country
            });
        },
        hasService(service: string): boolean {
            return this.services ? this.services.indexOf(service) >= 0 : false;
        }
    },

    getters: {
        hasIVR: state => {
            return state.ivr1 && state.ivr2;
        },
        numbers: state => {
            const result: {
                country: string;
                number: string;
                tariff: number;
            }[] = [];

            const { ivr1, ivr2 } = state;
            const activeCampaign: any = state.marketing?.current;
            const marketing: any = state.marketing;

            if (ivr1 && ivr1.phone_enabled === 1) {
                result.push({
                    country: 'nl',
                    number: !marketing.current ? ivr1.phone_number : marketing[activeCampaign].phone_number,
                    tariff: !marketing.current ? ivr1.phone_cpm : marketing[activeCampaign].phone_cpm
                });
            }
            if (ivr2 && ivr2.phone_enabled === 1) {
                result.push({
                    country: 'be',
                    number: ivr2.phone_number,
                    tariff: ivr2.phone_cpm
                });
            }
            return result;
        },
        activeCampagain: state => {
            const showBanners = ['valentine_65', 'bfridaysale_65', 'xmas2020', 'pasen_65', 'kingsday_65', 'liberation_65', 'happygame_65', 'happy_hour', 'happy_days', 'olympic_65', 'f1_happydays', 'halloween_65', 'happy_pumps_65', 'carnaval_65'];

            const activeCampaign: any = state.marketing?.current;
            const active = state.marketing && activeCampaign.length > 0;
            const activeInList = showBanners.includes(activeCampaign);
            return { name: activeCampaign, status: active && activeInList };
        },
        TabsServices: state => {
            const removed = ['peek', 'toy', 'sms', 'vipcam'];
            const filteredServices = state.services ? state.services.filter(item => !removed.includes(item)) : [];
            return filteredServices;
        },
        TileServices: state => {
            const removed = ['videocall', 'email', 'sms', 'vipcam'];
            const filteredServices = state.services ? state.services.filter(item => !removed.includes(item)) : [];
            return filteredServices;
        },
        FilterServices: state => {
            const removed = ['email', 'sms', 'vipcam'];
            const filteredServices = state.services ? state.services.filter(item => !removed.includes(item)) : [];
            return filteredServices;
        },
        HistoryServices: state => {
            return state.services ? state.services : [];
        }
    }
});
