import type { User, Role, UpdatePassword } from '@/ontology/user';
import type { PhotoPayload } from '@/stores/user';
import { transformIn, transformOut } from '../client/utils';
import { delEte, get, post, put } from '../fetch-extension';


export async function checkSession(login?: boolean) {
    const query = typeof login === 'undefined' ? undefined : { login: login ? 1 : 0 };
    const { error, result } = await get<User>('check_session', { query });

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function annonConnect(country: string) {
    const query = { country };
    const { error, result } = await get<User>('client/client_accounts/annon_connect', { query });

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function register(user: Object) {
    const { error, result } = await post<User>('client/client_accounts', {
        body: user
    });

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function confirmAccount(userId: number, token: string) {
    const { error } = await get<{ success: true }>(`auth/${userId}/confirm/${token}`, {
        credentials: 'include'
    });

    if (error) {
        return { error };
    }

    return { result: true };
}

export async function getTurnTile(token: string) {
    const { error, result } = await post<User>('auth/recaptcha', {
        body: { token },
    });

    if (error) {
        return { error };
    }

    return { result: (result as any).success };
}

export async function login(email: string, password: string, token: string, role: Role) {
    const { error, result } = await post<User>('auth/login', {
        body: { email, password },
        headers: new Headers({ 
            token: token,
            role: role,
        })
    });

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function update(user: User, secure?: Boolean) {
    const { error, result } = await put<User>(`client/client_accounts/${user.id}${secure ? '/secure' : ''}`, { body: transformOut(user) });

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function updatePassword(password: UpdatePassword, userid: number) {
    const { error, result } = await put<User>(`client/client_accounts/${userid}/pass`, { body: password });

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function subscribe(userId: number, performerId: number) {
    const { error, result } = await post<User>(`client/client_accounts/${userId}/subscriptions/${performerId}`);

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function unSubscribe(userId: number, performerId: number) {
    const { error, result } = await delEte<User>(`client/client_accounts/${userId}/subscriptions/${performerId}`);

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function payContent(userId: number, performerId: number, payload: PhotoPayload) {
    const { error, result } = await post<User>(`client/client_accounts/${userId}/tax/performer_accounts/${performerId}`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function logout() {
    return get<any>('auth/logout');
}

export async function remove(userId: number) {
    const { error, result } = await delEte<User>(`/client/client_accounts/${userId}`);

    if (error) {
        return { error };
    }

    return { result: transformIn(result) };
}

export async function getBoughtPhotos(query: Object) {
    const { error, result } = await get<any>('performer/photos_bought', { query });

    if (error) {
        return { error };
    }

    return { result };
}

export async function recoverPassword(email: string) {
    const { error, result } = await post<any>('client/client_accounts/forgot_password', {
        body: { account: email }
    });

    if (error) {
        return { error };
    }

    return { result };
}

export async function resetPassword(userId: number, password: string, token: string) {
    const { error, result } = await put<any>('/client/client_accounts/reset_password', {
        body: {
            id: userId,
            password: password,
            token: token
        }
    });

    if (error) {
        return { error };
    }

    return { result };
}

export async function getHistory(userId: number, query: Object) {
    const { error, result } = await get<any>(`client/client_accounts/${userId}/history`, { query });

    if (error) {
        return { error };
    }

    return { result };
}

export async function getPersonal(){
    const { error, result } = await get<any>(`client/client_accounts/personal_data`);

    if (error) {
        return { error };
    }

    return { result };
}