import type { User } from '@/ontology/user';
import type { boolean } from 'yup';

export function transformIn(p: any): User {
    const result: Partial<User> = {};
    for (let prop in p) {
        switch (prop) {
            case 'mobile_number':
                result.mobileNumber = p.mobile_number;
                break;
            case 'credits_ivr_code':
                result.creditsIvrCode = p.credits_ivr_code;
                break;
            case 'notification_mode':
                result.notificationMode = p.notification_mode;
                break;
            case 'notification_types':
                result.notificationTypes = {
                    online: p.notification_types.includes('SSA') && p.notification_mode!=0,
                    promotion: p.notification_types.includes('PRO') && p.notification_mode!=0,
                    message: p.notification_types.includes('MSG') && p.notification_mode!=0
                };
                break;
            default:
                (result as any)[prop] = p[prop];
        }
    }

    return result as User;
}

export function transformOut(u: User) {

    const result: any = {};
    for (let prop in u) {
        switch (prop) {
            case 'creditsUntil':
                break;
            case 'mobileNumber':
                result.mobile_number = u.mobileNumber;
                break;
            case 'creditsIvrCode':
                result.credits_ivr_code = u.creditsIvrCode;
                break;
            case 'notificationMode':
                result.notification_mode = u.notificationMode || wantsNotification(u.notificationTypes);
                break;
            case 'notificationTypes':
                result.notification_types = (u.notificationTypes.online ? 'SSA;' : '') + (u.notificationTypes.promotion ? 'PRO;' : '') + (u.notificationTypes.message ? 'MSG;' : '');
                break;
            default:
                result[prop] = (u as any)[prop];
        }
    }
    return result;
}

function wantsNotification( types: { online: boolean, promotion:boolean, message: boolean } ){
    return (types.online || types.promotion || types.message) ? 1 : 0
}
