<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
const Search = defineAsyncComponent(() => import('./../search/search.vue'));

import { useLocalizationStore } from '@/stores/localization';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import router from '@/router';

const { categories } = storeToRefs(useLocalizationStore());
const { toggleCatMenu } = useUserStore();
const route = useRoute();

const props = defineProps<{
    mobile: boolean;
}>();

function goCategory(category: string){
    router.push({
        name: 'grid',
        params: { category: category },
        query: { services: route.query.services ?  route.query.services : undefined }
    });
}
</script>

<template src="./catmenu.html"></template>

<style lang="scss">
@import './catmenu.scss';
</style>
