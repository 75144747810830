import { endCall, startCall } from '@/api/session';
import type { Routed } from '@/router';
import notifications from '@/socket';
import { match } from '@/utils';
import { defineStore } from 'pinia';
import { usePaymentStore } from './payment';
import { useCamStore } from './cam';
import { usePerformerStore } from './performer';
import { useAlertsStore } from './alerts';
import { useUserStore } from './user';

export type Status =
    'idle' |
    'initializing' |
    'calling' |
    'active' |
    'ending';

interface State extends Routed {
    status: Status,
    endReaseon: 'call-failed' | 'hangup' | 'she-hung-up' | undefined
}

export const useVideoCallStore = defineStore({
    id: 'videocall',
    state: (): State => ({
        status: 'idle', 
        endReaseon: undefined
    }),
    getters: {
        enabled(){
            const camStore = useCamStore();
            if (camStore.status != 'active' || camStore.type == 'peek'){
                return false;
            }
            if ( !( usePaymentStore().type == 'ivr' || camStore.type == 'videocall') ) {
                return false;
            }
            if (usePerformerStore().getById(camStore.performer).services['videocall'].status=='off'){
                return false;
            }
            return true;
        }
    },
    actions: {
        initialize(){
            notifications.subscribe('videoChat',  ({type} )=>{
                switch( type ){
                    case 'VIDEOCALL_ANSWER':
                        this.accepted();
                        break;
                    case 'VIDEOCALL_FAILED':
                        this.failed();
                        break;
                    case 'VIDEOCALL_DISCONNECT':
                        this.ended();
                        break;   
                }
            })
            notifications.subscribe('status_change', ( {sender, value})=>{
                if (sender == 'cam' && value == 'ended'){
                    this.status = 'idle'
                }
            });

        },
        //called when starting the videocall during a video session..
        async start(){
            if(!this.enabled){
                throw new Error('no calling when not paying with ivr buster');
            }

            this.setStatus('initializing');
            const { error } = await startCall(useCamStore().performer, usePaymentStore().code  || useUserStore().account.creditsIvrCode!.toString() );
            if (error){
                useAlertsStore().errorMessage( 'profile.session.videocall.callFailed' );
                this.setStatus('idle');
            } else {
                useAlertsStore().openMessage({
                    content: 'profile.session.videocall.calling',
                    class: 'info'
                })
                this.setStatus('calling');
            }
        },
        //called when the videocall is started at the beginning of the video session.
        started(){
            this.setStatus('calling');
        },
        async end(){
            if (['ending', 'idle'].includes(this.status)){
                return;
            }

            this.setStatus('ending');
            await endCall( usePaymentStore().code || useUserStore().account.creditsIvrCode!.toString() );
            this.setStatus('idle');
        },
        ended(){
            useAlertsStore().errorMessage( 'profile.session.videocall.callEnded' );
            this.setStatus('idle');
        },
        failed(){
            useAlertsStore().errorMessage( 'profile.session.videocall.callFailed' );
            this.setStatus('idle');
        },

        accepted(){
            useAlertsStore().openMessage({
                content: 'profile.session.videocall.callAccepted',
                class: 'info'
            })
            this.setStatus('active');
        },
        setStatus(value:Status){
            this.status = value;
        }
    }
})