import notifications, { type VideoChatUpdate } from '@/socket';
import { minutes, seconds } from '@/utils';
import type { SessionType } from '@/ontology/stream';
import { defineStore } from 'pinia';
import type { Status as CamStatus } from './cam';
import { useCamStore } from './cam';
import { useTeaserStore } from './teaser';
import type { Status as TeaseStatus } from './teaser';
import { useUserStore } from './user';

const debug = true && import.meta.env.MODE === 'development';

type PaymentStatus = 'none' | 'authorizing' | 'pending' | 'active' | 'idle' | 'ended';
interface State {
    status: PaymentStatus;
    //what status is the user aiming for?
    intention: 'none' | 'active';
    timeouts: {
        idle: any;
        ended: any;
    };
    code?: string;
    type?: 'credits' | 'ivr';
    sessionType: SessionType;
    start?: number;
    endReason?: string;
}

export const usePaymentStore = defineStore('payment', {
    state: (): State => ({
        status: 'none',
        intention: 'none',
        code: undefined,
        type: undefined,
        sessionType: 'none',
        start: undefined,
        endReason: undefined,
        timeouts: {
            idle: -1,
            ended: -1
        }
    }),
    actions: {
        initialize() {
            //TODO: strong type this parameter
            notifications.subscribe('ivr', (update: any) => {
                //sent when the consumer hangs up the phone
                if (update.message == 'HANGUP') {
                    this.ended('hangup');
                }
            });

            notifications.subscribe('videoChat', (update: VideoChatUpdate) => {
                //check if the message is actually for me..
                if (update.clientId != useUserStore().account!.id) {
                    //wtf, this videochat update is not for me??
                    return;
                }
                if (update.message == 'BROKE') {
                    this.ended('broke');
                }

                //sent when the session is closed because of a hangup
                if (update.message == 'HANGUP') {
                    this.ended('hangup');
                }
            });

            notifications.subscribe('status_change', this.dependencyChanged);
        },
        dependencyChanged({ sender }: { sender: string }) {
            //I'm only interested in status changes of peek, cam and teaser, for they only influence the
            //status of the payment stream
            if (!['cam', 'teaser'].includes(sender)) {
                return;
            }

            if (this.intention == 'none') {
                return;
            }

            this.setStatus(paymentStatus(useCamStore().status, useTeaserStore().status));
        },
        authorize(sessionType: SessionType, type: 'credits' | 'ivr', code?: string) {
            if (this.status == 'ended') {
                this.$reset();
                clearTimeout(this.timeouts.ended);
            }

            this.$patch({
                sessionType,
                type,
                code,
                intention: 'active'
            });
        },
        setStatus(value: PaymentStatus) {
            debug && console.log(`new desired status: ${value} while ${this.status} at ${Date.now()} started at ${this.start}`);
            if (this.status == value) {
                return;
            }

            // drop straight to none. Idle means there's an authorized payment stream
            // willing to deliver money.
            if (value == 'idle' && !this.start) {
                this.intention = 'none';
                value = 'none';
            }

            if (value == 'pending') {
                if (this.type == 'ivr' && !this.start) {
                    this.start = Date.now();
                }
            }

            if (value == 'active') {
                debug && console.log(`payment is nu actief met ${this.start}.`);
                if (!this.start) {
                    this.start = Date.now();
                }

                if (this.type == 'credits' && this.timeouts.idle == -1) {
                    debug && console.log('payment de start tijd wordt nu gezet, ook de interval dacht ik zo.');
                    //credits are taxed each minute once. So if a user does not have an active payment stream
                    //at that time, the stream stops
                    this.timeouts.idle = setInterval(() => {
                        debug && console.log(`payment how idle are we anyway? ${this.status}`);
                        if (this.status == 'idle') this.ended('timeout');
                    }, minutes(1));
                }
            }

            if (value == 'none') {
                this.intention = 'none';
            }

            this.status = value;
            debug && console.log(`new actual status: ${value} started at ${this.start}`);
        },
        //not called anymore; handled by inferring stuff from dependency statechanges
        authorizationFailed(reason: string) {
            //als de reason is dat de ivr code fout is, "end" dan de stream
            if (reason == 'ongeldige ivr code') {
                return this.ended('hangup');
            }

            //als de reason is dat de client neit genoeg credits heeft, "end" dan de stream
            if (reason == 'onvoldoende credits') {
                return this.ended('broke');
            }

            //if the authorization failed because of another reason, and this payment stream has already started,
            //return to idle. Otherwise, return to 'none', but preserve the ivr
            if (this.start) {
                this.setStatus('idle');
            } else {
                this.setStatus('none');
            }
        },
        ended(reason?: string) {
            debug && console.log(`payment ended want ${reason}`);
            if (this.status == 'ended') {
                return;
            }

            clearInterval(this.timeouts.idle);

            this.$patch({
                endReason: reason,
                code: undefined,
                intention: 'none',
                timeouts: {
                    idle: undefined,
                    ended: undefined
                }
            });
            this.setStatus('ended');

            this.timeouts.ended = setTimeout(() => {
                if (this.status == 'ended') this.$reset();
            }, seconds(1));
        }
    }
});

export function paymentStatus(cam: CamStatus, teaser: TeaseStatus): PaymentStatus {
    //this assumed the 'intention' of the payment stream is 'active' (ie the status that store/entity wants to reach)
    //prettier-ignore
    debug && console.log(`payment dep change: ${cam} ${teaser}`)
    const matrix: { [cam: string]: { [teaser: string]: PaymentStatus } } = {
        'idle': { idle: 'idle', choosing_payment: 'idle', checking: 'authorizing', active: 'active', ending: 'idle', ended: 'idle' },
        'choosing_payment': { idle: 'idle', active: 'active' },
        'checking': { idle: 'authorizing', active: 'active' },
        'authorizing': { idle: 'pending', active: 'active' },
        'awaiting-teaser-end': { active: 'active', ending: 'active', ended: 'pending' },
        'initiating': { idle: 'pending' },
        'initializing': { idle: 'pending' },
        'limbo': { idle: 'pending' },
        'active': { idle: 'active' },
        'ending': { idle: 'active' },
        'ended': { idle: 'idle', active: 'active' },
        'error': { idle: 'idle' }
    };

    if (!matrix[cam]) {
        return 'idle';
    }

    const result = matrix[cam][teaser];
    if (!result) {
        //if there's no direct match, use the cam-idle match
        debug && console.log(`payment: ${cam} while ${teaser}?? I'll be damned`);
        debug && console.log('payment status ' + matrix[cam]['idle'] + 'wordt t!');
        return matrix[cam]['idle'];
    }

    return result;
}
