import sanitizeHtml from 'sanitize-html';
import type { LocationQueryValue } from 'vue-router';
import { useUserStore } from '@/stores/user';
import config from '@/config';
import type { Performer } from './ontology/performer';
import platform from 'platform';
import i18n from '@/translations';

export function getStaticImages(name: string, format?: string) {
    return `/themes/${config.Themename}/assets/images/${name}.${!format ? 'gif' : format}`;
}

export function getParameterByName(name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
        results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getConfirmation() {
    const [_, confirm, userId, token] = window.location.pathname.split('/');
    if (!(confirm == 'confirm' && userId && token)) {
        return undefined;
    }

    return { userId: parseInt(userId), token };
}

export function asInt(routeParam: string[] | string | LocationQueryValue | LocationQueryValue[]): number {
    let toParse: string;
    if (Array.isArray(routeParam)) {
        toParse = routeParam[0] as string;
    } else {
        toParse = routeParam as string;
    }
    return parseInt(toParse);
}

export function resizeSessionFont() {
    const fonts = document.getElementsByClassName('chat__messages-messages') as any;
    if (fonts[0].classList.contains('medium')) {
        fonts[0].classList.remove('medium');
        fonts[0].classList.add('large');
    } else if (fonts[0].classList.contains('large')) {
        fonts[0].classList.remove('large');
    } else {
        fonts[0].classList.add('medium');
    }
}

export function performerSnapShot(performer: any) {
    if (!performer) {
        return '';
    }

    const snapshotservice = performer.services.showsnapshot ? performer.services.showsnapshot.intention : false;
    return { image: snapshotservice, url: !snapshotservice ? `${getAvatar(performer, 'medium')}` : `${config.SnapshotUrl}/snapshot/${performer.id}` };
}

export function samish(one: number, two: number): boolean {
    return Math.trunc(one * 100) == Math.trunc(two * 100);
}

export function minutes(count: number) {
    return count * seconds(60);
}

export function seconds(count: number) {
    return count * 1000;
}

export function numbersOnly(event: KeyboardEvent | any) {
    let key = event.which || event.keyCode;
    if (key === 13) {
        return;
    } else if ((key && (key <= 47 || key >= 58) && key != 8) || key === 13) {
        event.preventDefault();
    }
}

export function currency(amount?: number) {
    if (!amount) amount = 0;
    return new Intl.NumberFormat('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
}

export function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function scrollBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
}

export function startFromTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
}

export function getAvatar(performer: Performer, size: 'small' | 'medium' | 'large' = 'large') {
    const user = useUserStore();

    if (user.safeMode && performer.safeAvatar?.safe_version) {
        return `${config.ImageUrl}pimg/${performer.id}/${size}/${performer.safeAvatar.name}`;
    }

    if (!user.safeMode && performer.avatar) {
        return `${config.ImageUrl}pimg/${performer.id}/${size}/${performer.avatar.name}`;
    }

    return `/themes/${config.Themename}/assets/images/placeholder-grey.jpg`;
}

export function getAvatarInbox(performerId: number, avatar: string) {
    if (performerId && avatar) {
        return `${config.ImageUrl}pimg/${performerId}/small/${avatar}`;
    } else {
        return `/themes/${config.Themename}/assets/images/placeholderprofile.jpg`;
    }
}

export function getAvatarHistory(payment: any) {
    if (payment.id_performer && payment.avatar_name) {
        return `${config.ImageUrl}pimg/${payment.id_performer}/large/${payment.avatar_name}`;
    } else {
        return `/themes/${config.Themename}/assets/images/placeholder-grey.jpg`;
    }
}

export function getAvatarPerformerList(performerId: number, avatar: string) {
    if (performerId && avatar) {
        return `${config.ImageUrl}pimg/${performerId}/large/${avatar}`;
    } else {
        return `/themes/${config.Themename}/assets/images/placeholderprofile.jpg`;
    }
}

export function reArrangeMedia(photos: any, media: any) {
    const photosList = photos ? photos : [];
    const videosList = media ? media : [];
    let mediaList = photosList;
    let mediaLength = photosList.length + videosList.length;

    if (mediaLength < 5) {
        mediaList = mediaList.concat(Array(5 - mediaLength).fill({ placeholder: true }));
    }

    if (videosList.length < 0) {
        return mediaList;
    } else {
        //deep copy so it should not trigger computed getter again
        const newMediaList = mediaList.slice();
        let s = 2;
        let i = 0;

        for (const media of videosList) {
            if (s <= newMediaList.length) {
                newMediaList.splice(s, 0, videosList[i] as any);
                s = s + 5;
                i++;
            }
        }

        //put remaining videos to end of list
        if (i < videosList.length) {
            for (let x = i; x < videosList.length; x++) {
                newMediaList.push(videosList[x] as any);
            }
        }

        return newMediaList;
    }
}

export function getSliderImages(performer: Object, photo: Object | any, size?: string) {
    if (!photo.name) {
        return `/themes/${config.Themename}/assets/images/placeholderprofile.jpg`;
    }

    const user = useUserStore();
    const sizes = size ? `${size}/` : window.innerWidth > 992 ? '' : 'large/';

    if (user.safeMode && photo.safe_version) {
        return `${config.ImageUrl}pimg/${performer}/${sizes}${photo.name}`;
    }

    if (!user.safeMode) {
        return `${config.ImageUrl}pimg/${performer}/${sizes}${photo.name}`;
    }

    return `/themes/${config.Themename}/assets/images/placeholderprofile.jpg`;
}

export function transformCupSize(size: string): string {
    switch (size.toLowerCase()) {
        case 'xsmall':
            return i18n.global.t('profile.breastsizes.xsmall');
        case 'small':
            return i18n.global.t('profile.breastsizes.small');
        case 'medium':
            return i18n.global.t('profile.breastsizes.medium');
        case 'large':
            return i18n.global.t('profile.breastsizes.large');
        case 'xlarge':
            return i18n.global.t('profile.breastsizes.xlarge');
        default:
            return size;
    }
}

export function getSliderVideos(video: string) {
    //fix for accept testing...
    return `${config.FullApiUrl.replace('accept-', '')}${config.VodServer}/${video}`;
}

export function kebabToCamel(kebab: string) {
    return kebab
        .split('_')
        .map((part, index) => (index && part ? part[0].toUpperCase() + part.slice(1) : part))
        .join('');
}

// checks if 'pattern' is a subset of 'message'
// eg match( {id:3, text:"bla"}, {text:"bla"} ) => true
export function match(message: any, pattern: any): boolean {
    for (var prop in pattern) {
        if (!message) {
            return false;
        }
        if (!(prop in message)) {
            return false;
        }

        if (typeof pattern[prop] === 'object' && typeof message[prop] === 'object') {
            //recursive matching. No guards!
            if (!match(message[prop], pattern[prop])) {
                return false;
            }
        } else if (typeof pattern[prop] === 'function') {
            if (!pattern[prop](message[prop])) {
                return false;
            }
        } else if (pattern[prop] != message[prop]) {
            return false;
        }
    }

    return true;
}

export function among(options: any[]) {
    return (what: any) => options.includes(what);
}

//returns all items from from that aren't in what
export function subtract(what: any[], from: any[]): any[] {
    const result = [];
    for (var item of from) {
        if (what.indexOf(item) == -1) {
            result.push(item);
        }
    }

    return result;
}

//randomly picks an item from a list
export function pickOne(from: any[]) {
    if (!from.length) {
        return undefined;
    }
    const ix = Math.floor(Math.random() * from.length);
    return from[ix];
}

export function encodeHTML(s: string) {
    return s
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
        .replace(/:([^:]+):/g, '<i>$1</i>');
}

export function sleep(delay: number): Promise<void> {
    return new Promise((resolve, _) => {
        setTimeout(resolve, delay);
    });
}

export function bigBlackPoster() {
    return new Promise<string>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        canvas.width = 2000;
        canvas.height = 2000;

        canvas.toBlob(blob => {
            if (!blob) {
                reject('unblobbable');
            } else {
                const result = URL.createObjectURL(blob);
                console.log(result);
                resolve(result);
            }
        });
    });
}

export function isTooApple() {
    //tooApple means the platform is too apple to start a chat (not peek) video unmuted
    //All safaris have trouble, and if you don't use Apple's browser, iOS is always too apple.
    //TODO: check ipad os?
    return platform.name == 'Safari' || platform.os?.family == 'iOS';
}

export function extractLocation(location: string): string {
    if (!location) {
        return i18n.global.t('profile.netherlands');
    }

    const trimmedLocation = location.trim();
    const parts = trimmedLocation.split('/');
    const lastPart = parts
        .map(part => part.trim())
        .filter(part => part) // Remove empty parts
        .pop(); // Get the last non-empty part

    if (!lastPart) {
        return i18n.global.t('profile.netherlands');
    }

    const capitalizedLastPart = lastPart.charAt(0).toUpperCase() + lastPart.slice(1);

    return lastPart.toLowerCase() === 'nl' ? i18n.global.t('profile.netherlands') : capitalizedLastPart;
}

export function sanitize(text: string): string {
    return sanitizeHtml(text, {
        allowedTags: [],
        allowedAttributes: {},
        allowedIframeHostnames: []
    });
}

export function getQueryString(queryObject: any): string {
    return Object.keys(queryObject)
        .filter(k => queryObject && queryObject[k] !== undefined && queryObject[k] !== '')
        .map(k => (queryObject ? `${encodeURIComponent(k)}=${encodeURIComponent(queryObject[k])}` : ''))
        .join('&');
}

export function openTab(url: string, desc?: string) {
    //setTimeout(() => window.open(url, '_blank', desc), 200);
    return window.open(url, '_blank', desc);
}

export function loadExternalScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.defer = true;
    document.head.appendChild(script);
    return script;
}
