import notifications from '@/socket';
import { defineStore } from 'pinia';
import type { Thread, Message } from '@/ontology/messaging';
import { getThreads, getThread, sendMessage, payMessage, deleteEmail, getMessagesAfter, deleteThread } from '@/api/messaging';
import type { Paged } from '@/api/response';
import { encodeHTML, match, sanitize } from '@/utils';
import { useUserStore } from './user';
import { merge } from '@/api/messaging/utils';
import { useAlertsStore } from './alerts';
import i18n from '@/translations';
import { usePerformerStore } from './performer';

type Status = 'idle' | 'loading_threads' | 'loading_messages' | 'sending_message';
interface State {
    threads?: Paged<Thread>;
    selectedThread?: Paged<Message>;
    status: Status;
    statusResolvers: ((status: Status) => void)[];
}

interface EmailNotification {
    performerId: number,
    subject: string, 
    id: number,
    type: 'new',
    replyId: number,
    free: boolean
}

interface AuthenticationNotification {
    type: 'loggedin' | 'loggedout';
}

export const useMessageStore = defineStore('Message', {
    state: (): State => ({
        threads: undefined,
        selectedThread: undefined,
        status: 'idle',
        statusResolvers: []
    }),
    actions: {
        initialize() {
            notifications.subscribe('authentication', this.handleNotification);
            notifications.subscribe('email', this.handleNotification);
        },
        //TODO: is this strong-typable?
        handleNotification(update: EmailNotification | AuthenticationNotification) {
            const rules = [
                {
                    when: { type: 'loggedin' },
                    do: () => this.loadThreads()
                },
                {
                    when: { type: 'loggedout' },
                    do: () => (this.threads = undefined)
                }, 
                {
                    when: { type: 'new' },
                    do: ()=>this.newMessage(update as EmailNotification)
                }
            ];
            const toMatch = update;

            const rule = rules.find(check => match(toMatch, check.when));
            if (rule && rule.do) {
                rule.do();
            } else {
                console.log('unhandleable now for message:');
                console.dir(toMatch);
            }
        },

        async loadThreads(offset = 0, limit = 7) {
            this.setStatus('loading_threads');
            const { error, result } = await getThreads(offset, limit, true);
            this.setStatus('idle');
            if (error) {
                //TODO: handle not loading of threads. Most unlikely, if it fails, something big went wrong..
                return;
            }

            this.threads = result;
        },

        async loadNextThreads(limit = 7) {
            this.setStatus('loading_threads');
            if (!this.threads) {
                throw new Error('call loadThreads first. Only then can one load more.');
            }

            const { error, result } = await getThreads(this.threads.offset + this.threads.items.length, limit, true);
            if (error) {
                this.setStatus('idle');
                //TODO: most unlikely: a thread that doesn't load properly
                return;
            }

            if (!result) {
                throw new Error('impossible');
            }

            const difference = result.total - this.threads.total;
            if (difference > 0) {
                //complicated prepend shit...
                const { error: errorPrepend, result: toPrepend } = await getThreads(0, difference, true);
                if (errorPrepend) {
                    this.setStatus('idle');
                    //TODO: handle that error! What could possibly go wrong?
                    return;
                }
                this.threads.items = toPrepend!.items.concat(this.threads.items).concat(result.items);
            } else {
                this.threads.items = this.threads.items.concat(result.items);
            }

            this.setStatus('idle');
        },

        async loadMessagesForPerformer(withId: number) {
            if (this.status != 'idle') {
                while ((await this.nextStatus()) != 'idle') {}
            }
            this.selectedThread = undefined;

            const threadWithPerformer = this.threads?.items.find(({ performerId }) => withId == performerId);
            if (threadWithPerformer) {
                await this.loadMessages(threadWithPerformer.id);
            } else {
                this.selectedThread = { offset: 0, total: 0, items: [] };
            }
        },

        async loadMessages(messageId: number, limit?: number) {
            this.setStatus('loading_messages');
            this.selectedThread = undefined;
            const { error, result } = await getThread(messageId, 'email', 0, limit);
            if (error) {
                //TODO: most unlikely: a thread that doesn't load properly
                return;
            }

            this.selectedThread = {
                total: result.total,
                offset: result.offset,
                items: result.items
            };

            // Set translate boolean
            for (const message of this.selectedThread.items as any) {
                if(message.from_language){
                    message.original = false;
                }
            }

            this.setStatus('idle');
        },

        async loadNextMessages() {
            if (!this.selectedThread) return;

            const messageId = this.selectedThread.items[0].id;

            this.setStatus('loading_messages');
            const { error, result } = await getThread(messageId, 'email', this.selectedThread.offset + this.selectedThread.items.length);

            if (error) {
                //todo: how to handle this error?
                this.setStatus('idle');
                return;
            }

            //Good lord, there are some new messages. Let's load them and prepend.
            const difference = result.total - this.selectedThread.total;
            if (difference > 0) {
                const { error: prependError, result: prepend } = await getThread(messageId, 'email', 0);

                if (prependError) {
                    //good lord, failed at loading this?? Bad news TODO: throw global error
                    this.setStatus('idle');
                    return;
                }

                //
                this.selectedThread.items = prepend.items.concat(this.selectedThread.items).concat(result.items.slice(difference));
                this.selectedThread.total = result.total;
            } else {
                this.selectedThread.items = this.selectedThread.items.concat(result.items);
            }

            this.setStatus('idle');
        },

        async newMessage( notification:EmailNotification ){
            if (!this.threads){
                return;
            }

            //is this message sent in the selectedThread?
            if (this.inSelectedThread( notification.replyId )){
                const oldId = this.getSelectedThreadId();

                //load the new message(s)
                const { error, result: newMessages}  = await getMessagesAfter( notification.replyId );
                if (error){
                    throw "Error loading additional messages"
                }
                this.selectedThread = merge(this.selectedThread!, newMessages!);
                if (oldId != this.getSelectedThreadId()){
                    this.updateThread( oldId, { id:this.getSelectedThreadId(), readStatus: "NEW" } );
                }

                this.threads.items = this.threads.items.sort((one, other)=>other.id - one.id );
            } else {

                let found = this.threads.items.find( ({id})=> notification.replyId == id );
                //if the thread is loaded already, find that thread and update it 
                if (found){
                    this.updateThread( found.id, {id: notification.id, readStatus: "NEW"} );
                    this.threads.items = this.threads.items.sort((one, other)=>other.id - one.id );
                } else if (!found){
                    //reload all threads; it was sent to a thread currently not here, it will appear at the top once loaded
                    await this.loadThreads( 0, this.threads!.items.length+1);
                }

                //we need the performer name who sent this thing..
                const performers = usePerformerStore();
                let sender = performers.getById( notification.performerId );
                if (!sender) {
                    sender = await performers.loadPerformerById( notification.performerId );
                }

                useAlertsStore().openMessage( {
                    class: "success", translate: false, content: i18n.global.t("email.alerts.successReceived", {performer: sender.nickname})
                })

            }           

            const account = useUserStore().account;
            if (account.totalNotifications){
                account.totalNotifications += 1;
            } else {
                account.totalNotifications = 1;
            }

        },

        setStatus(value: Status) {
            if (value == this.status) {
                return;
            }

            this.status = value;
            this.statusResolvers.forEach(f => f(value));
            this.statusResolvers = [];
        },

        //await this if you need to wait for another state.
        async nextStatus() {
            return new Promise<Status>(resolve => {
                this.statusResolvers.push(resolve);
                //TODO: how about a rejection? Maybe.. a global timeout for the status?
            });
        },

        hasMoreMessages() {
            if (!this.selectedThread) {
                return false;
            }

            return this.selectedThread.total > this.selectedThread.offset + this.selectedThread.items.length;
        },

        async sendMessage(content: string, toPerformer: number) {
            return this.sendEmail(content, toPerformer);
        },

        async startThread( content: string, toPerformer:number, subject: string = ''){
            content = sanitize(content);
            if (subject) {
                subject = sanitize(subject);
            }
            this.status = 'sending_message';
            const message: Partial<Message> = {
                performerId: toPerformer,
                content, 
                subject,
                type: 'email'
            }

            const { error } = await sendMessage( message );

            if (error){
                this.status = 'idle';
                return error;
            }

            await this.loadThreads();
            this.status = 'idle';
        },

        async deleteEmail(messageId: number) {
            if (!this.selectedThread){
                return;
            }
            const { error, result } = await deleteEmail(messageId);

            if (error) {
                return error;
            }

            if (!result) {
                throw new Error('Impossible');
            }

            const index = this.selectedThread.items.findIndex( ({id})=> id == messageId );
            if (index > -1){
                this.selectedThread.items.splice(index, 1);
            }
        },

        async deleteThreads(messageIds: number[]){
            for( const id of messageIds ){
                await deleteThread(id);
            }
        },

        async sendEmail(content: string, toPerformer: number) {
    
            if (!(this.selectedThread?.items.length && this.selectedThread.items[0].performerId == toPerformer)) {
                await this.loadMessagesForPerformer(toPerformer);
            }

            if (!this.selectedThread) {
                throw new Error('impossible');
            }

            content = sanitize(content);
            const repliedFrom = this.selectedThread.items.length ? this.selectedThread.items[0].id : undefined;
            const subject = this.selectedThread.items.length ? this.selectedThread.items[0].subject : '';

            const message: Partial<Message> = {
                performerId: toPerformer,
                content,
                type: 'email',
                repliedFrom,
                subject
            };
            const { error, result } = await sendMessage(message);

            if (error) {
                return error;
            }

            if (!result) {
                throw new Error('Impossible');
            }

            this.selectedThread.items.unshift(result);
        },

        async buyEmail(messageId: number) {
            const clientId = useUserStore().account.id!;
            if (!this.selectedThread) {
                //should not be possible
                return;
            }

            const message = this.selectedThread.items.find(({ id }) => id == messageId);

            if (!message) {
                //TODO: er is iets fout gegaan??
                return;
            }

            const { error, result } = await payMessage(clientId, message);

            if (error) {
                return error;
            }

            // maybe a new message was sent/received while paying for this message, thus changing the index of the original message
            // Let's go and find the message again
            const index = this.selectedThread.items.findIndex(({ id }) => id == messageId);
            if (index == -1) {
                //impossible
                return;
            }
            this.selectedThread.items[index] = result;

            //now check if there are any NEW messages left in this thread. If not, mark the thread as OLD
            if (!this.selectedThread.items.some( ( { readStatus } )=> readStatus == "NEW" )){
                this.updateThread( this.getSelectedThreadId(), { readStatus: "OLD" });
            }

            const account = useUserStore().account;
            if (account.totalNotifications){
                account.totalNotifications -= 1;
            }

        },

        inSelectedThread(messageId:number){
            if (!this.selectedThread){
                return false;
            }
            return this.selectedThread?.items.some( ({id}) => id==messageId );
        },

        getSelectedThreadId(){
            if (!this.selectedThread){
                return -1;
            }
            if (!this.selectedThread.items.length){
                return -1;
            }
            return this.selectedThread.items[0].id;
        },

        updateThread(withId:number, update:Partial<Thread>){
            if (!this.threads){
                return;
            }
            const thread = this.threads.items.find( ({id})=> id===withId );
            if (!thread){
                return;
            }

            Object.assign( thread, update );
        }
        
    }
});
