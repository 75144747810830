import type { Response } from '../response';
import { transformCategoryData, type CategoryData } from '../../ontology/category';
import { get } from '../fetch-extension';

interface CacheEntry {
    data?: CategoryData;
    promise?: Promise<Response<CategoryData>>; // Store promise to prevent multiple asynchronous calls
}

const cache: Record<string, CacheEntry | undefined > = {};

export default async function getCategory(category: string): Promise<Response<CategoryData>> {
    const cachedEntry = cache[category];
    if (cachedEntry && cachedEntry.data) {
        return { result: cachedEntry.data };
    }

    if (cachedEntry && cachedEntry.promise) {
        return await cachedEntry.promise;
    }

    const fetchPromise = async () => {
        const { error, result } = await get<CategoryData>(`/category/${category}`);

        if (error) {
            return { error };
        }

        if (result) {
            const transformedData = transformCategoryData(result);
            cache[category] = { data: transformedData };
            return { result: transformedData };
        }
        return { error: { code: 500, message: "No result and no error returned" } };
    }

    cache[category] = { promise: fetchPromise() }
    return await cache[category]!.promise!;


}


