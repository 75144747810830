import type { Message, Thread } from '@/ontology/messaging';
import { delEte, get, post } from '../fetch-extension';
import type { Paged, Response } from '../response';
import { transformThreadIn, transformMessageIn, transformMessageOut } from './utils';

export async function getThreads(offset = 0, limit = 20, singleThread = false): Promise<Response<Paged<Thread>>> {
    const query:{ offset: number; limit: number; group?: string } = {
        offset, limit
    }
    if (singleThread){
        query.group = "perf"
    }

    const { error, result } = await get<{ total: number; messages: any[] }>('/messages/threads', { query });
    if (error) {
        return { error };
    }
    if (!result) {
        throw new Error('impossible');
    }

    const { total, messages } = result;

    const threads = {
        total,
        offset,
        items: messages.map(thread => transformThreadIn(thread))
    };

    return { result: threads };
}

//gets the slice of messages for a message that belongs to the thread, limited by offset and limit
// TODO: add limit
export async function getThread(messageId: number, type: 'email' | 'sms', offset = 0, limit?: number, group = 'perf') {
    const query: { offset: number; limit?: number; group: string } = { offset, group };

    if (limit !== undefined) {
        query.limit = limit;
    }

    const { error, result } = await get<{ total: number; messages: any[] }>(`/messages/thread/${type}/${messageId}`, { query } );
    if (error) {
        return { error };
    }
    if (!result) {
        throw new Error('impossible');
    }

    const { total, messages } = result;

    return {
        result: {
            total,
            offset,
            items: messages.map(msg => transformMessageIn(msg))
        }
    };
}

//only gets the newest 100 messages in a thread. Should be sufficient though
export async function getMessagesAfter(messageId: number, type: 'email'|'sms' = 'email'){
    const { error, result } = await get<{ total: number, messages: any[] }>(`/messages/thread/update/${type}/${messageId}?limit=100`);
    if (error){
        return { error };
    }

    if (!result){
        throw new Error("impossible");
    }

    const { total, messages } = result;
    return {
        result: {
            total, 
            offset: 0,
            items: messages.map(msg => transformMessageIn(msg) )
        }
    }
}

// thuis_api_email_thread_add:
//     pattern: /api/messages/thread/message
//     defaults: {_controller: ThuisAccountBundle:EmailThread:add, _format:json}
//     methods: POST

//     {
//         "account_id": "150",
//         "content": "Als ik dit bericht verstuur, wat gebeurt er dan?",
//         "type": "email",
//         "reply_id": 1903475,
//         "subject": "Stuur es wat terug?"
//     }
export async function sendMessage(item: Partial<Message>): Promise<Response<Message>> {
    const { error, result } = await post('/messages/thread/message', {
        body: transformMessageOut(item)
    });

    if (error) {
        return { error };
    }

    if (!result) {
        throw new Error('impossible');
    }

    return { result: transformMessageIn(result) };
}

export async function deleteEmail(messageId: number): Promise<Response<Message>> {
    const { error, result } = await delEte(`/messages/thread/email/${messageId}?mode=1`);

    if (error) {
        return { error };
    }

    if (!result) {
        throw new Error('impossible');
    }

    return { result: transformMessageIn(result) };
}

export async function deleteThread(messageId:number){
    return delEte(`/messages/thread/email/${messageId}?mode=2&group=perf`);
}

// client/client_accounts/56121261/tax/performer_accounts/158
// {
//     "serviceType": "EMAIL",
//     "emailId": 2426375
// }
export async function payMessage(clientId: number, item: Partial<Message>) {
    const { error, result } = await post(`client/client_accounts/${clientId}/tax/performer_accounts/${item.performerId}`, {
        body: {
            serviceType: 'EMAIL',
            emailId: item.id
        }
    });

    if (error) {
        return { error };
    }

    return { result: transformMessageIn(result) };
}

export async function sendMsg(payload: any){
    const { error, result } = await post(`session/chat_message`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: result };
}
