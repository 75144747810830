<div class="header__back" v-if="back">
    <div class="container back">
        <div class="back__content">
            <a class="toOld" @click="makeBetaUnsticky()" href="https://classic.thuis.nl?utm_source=new-website&utm_medium=bar&utm_campaign=to+old">
                <i class="fa-sharp fa-solid fa-backward"></i>
                <span>{{ $t('header.back.content') }}</span>
            </a>
        </div>
        <div class="back__close fa-solid fa-xmark" @click="close"></div>
    </div>
</div>