export function useSeo() {

    const setTitle = (title: string) => {
        window.document.title = title;
    }

    const setDescription = (description: string) => {
        createMetaTag('description', description);
    }

    const setKeywords = (keywords: string) => {
        createMetaTag('keywords', keywords);
    }

    const setGraphData = (type: string, content: string) => {
        createOGTag(type, content);
    }

    const setCanonical = (href: string) => {
        createLinkTag('canonical', href);
    }

    const createLinkTag = (rel: string, href: string) => {
        const existingTag = document.querySelector(`link[rel='${rel}']`) as HTMLLinkElement;

        if(existingTag !== null){
            existingTag.href = href;
            return;
        }

        const tag = document.createElement('link') as HTMLLinkElement;

        tag.rel = rel;
        tag.href = href;

        document.head.appendChild(tag);
    }

    const createMetaTag = (name: string, content: string) => {
        const existingTag = document.querySelector(`meta[name='${name}']`) as HTMLMetaElement;

        if(existingTag !== null){
            existingTag.content = content;
            return;
        }

        const metaTag = document.createElement('meta') as HTMLMetaElement;

        metaTag.name = name;
        metaTag.content = content;

        document.head.appendChild(metaTag);
    }

    const createOGTag = (property: string, content: string) => {
        const existingTag = document.querySelector(`meta[property='${property}']`) as HTMLMetaElement;

        if(existingTag !== null){
            existingTag.content = content;
            return;
        }

        const metaTag = document.createElement('meta') as HTMLMetaElement;
        metaTag.setAttribute('property', property);
        metaTag.content = content;

        document.head.appendChild(metaTag);
    }

    return {
        setTitle,
        setDescription,
        setKeywords,
        setCanonical,
        setGraphData
    }
}