<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import router from '@/router';
import { useFilterStore, type ServiceType } from '@/stores/filter';

const props = defineProps<{
    mobile?: boolean;
}>();

const filterStore = useFilterStore();


const isEnabled = computed(() => {
    const currentRoute = router.currentRoute.value;
    const ageFilters = [...filterStore.ageFilters].filter(p => p.enabled);
    const serviceFilters = [...filterStore.serviceFilters];    
    //only show when on grid...    
    return (ageFilters.length > 0 || serviceFilters.length > 0) && currentRoute?.name === 'grid';
})

function removeServiceFilter(filter: string) {
    filterStore.removeServiceFilter(filter);
    filterStore.applyFilter();
}

function removeAgeFilter(ageFilter: any) {
    filterStore.toggleAgeFilter(ageFilter);
     filterStore.applyFilter();
}
</script>

<template src="./filtertag.html"></template>

<style lang="scss">
@import './filtertag.scss';
</style>