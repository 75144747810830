import type { PurchaseOptions } from '../../ontology/purchaseOptions';
import { get } from '../fetch-extension';
/*
Fetches some settings about purchases:
 - fees: How many credits should one purchase to get what bonus?
 - packages: A purchase consists of one or more packages; what amounts are the building blocks of one purchase?
 - payment_methods: what payment providers are there to choose from and what are their 
 - promo: enter some code(s) and get an extra credit bonus
*/
export async function updateBalanceInfo() {
    return get<PurchaseOptions>('client/client_accounts/updatebalanceinfo');
}
