import notifications from '@/socket';
import { defineStore } from 'pinia';
import type { Message } from '@/ontology/messaging';
import { encodeHTML, match, sanitize } from '@/utils';

import { sendMsg } from '@/api/messaging';

interface State {
    messages: Message[];
    performerId: number;
    typing: any;
    count: number;
}

interface ChatMessage {
    senderType: 'ROLE_PERFORMER' | 'ROLE_CLIENT' | 'ROLE_SYSTEM' | 'ROLE_TOY';
    message: string;
    messageTranslate?: string;
}

interface TypingReceivedMessage {
    recentTyping: boolean;
    inBuffer: boolean;
}

const defaultMessage: Message = {
    billingStatus: 'FREE',
    content: '  ...   ',
    date: new Date(),
    folder: 'DRAFT',
    id: Date.now(),
    subject: '',
    performerId: -1,
    readStatus: 'OLD',
    sentBy: 'PERFORMER',
    translate: false,
    type: 'chat'
};

export const useChatStore = defineStore('Chat', {
    state: (): State => ({
        messages: [],
        performerId: -1,
        typing: false,
        count: 0
    }),
    actions: {
        initialize() {
            this.count = 0;
            notifications.subscribe('msg', this.handleMessage);
            notifications.subscribe('msgt', this.handleMessage);
            notifications.subscribe('typing_received', this.handleTyping);
        },

        handleMessage({ senderType, message, messageTranslate }: ChatMessage) {
            let sentBy: 'CLIENT' | 'PERFORMER' | 'SYSTEM' | 'TOY' = 'SYSTEM';
            switch (senderType) {
                case 'ROLE_PERFORMER':
                    sentBy = 'PERFORMER';
                    break;
                case 'ROLE_CLIENT':
                    sentBy = 'CLIENT';
                    break;
                case 'ROLE_TOY':
                    sentBy = 'TOY';
                    break;
            }
            const msg: Message = {
                ...defaultMessage,
                ...{
                    date: new Date(),
                    id: Date.now(),
                    folder: 'INBOX',
                    performerId: this.performerId,
                    content: sanitize(message),
                    contentTranslate: messageTranslate ? sanitize(messageTranslate) : undefined,
                    sentBy,
                    translate: !!messageTranslate
                }
            };

            const first = this.messages[0];
            //check if the performer is typing..
            if (first && first.folder == 'DRAFT' && first.sentBy == 'PERFORMER') {
                //replace the 'typing message with the received one if sent by performer
                if (msg.sentBy == 'PERFORMER') {
                    this.messages[0] = msg;
                    this.count++;
                } else {
                    //otherwise, insert it after
                    this.messages.splice(1, 0, msg);
                    this.count++;
                }
            } else {
                this.messages.unshift(msg);
                this.count++;
            }

            if (msg.sentBy == 'PERFORMER') {
                clearTimeout(this.typing);
                this.typing = false;
            }
        },

        handleTyping({ recentTyping, inBuffer }: TypingReceivedMessage) {
            if (!this.typing) {
                const message: Message = {
                    ...defaultMessage,
                    ...{
                        id: Date.now(),
                        performerId: this.performerId
                    }
                };
                this.messages.unshift(message);
            } else {
                clearTimeout(this.typing);
            }
            this.typing = setTimeout(this.typingStopped, 3000);
        },

        typingStopped() {
            clearTimeout(this.typing);
            this.typing = false;
            if (this.messages[0] && this.messages[0].folder == 'DRAFT') {
                this.messages.splice(0, 1);
            }
        },

        resetMessages() {
            this.count = 0;
            this.messages = [];
        },

        async send(content: string, performer: number) {
            content = sanitize(content);
            this.performerId = performer;
            if (!this.performerId) {
                return;
            }

            const { error, result } = await sendMsg({ msg: content, receiver: this.performerId });
            if (error) {
                return error;
            }

            this.handleMessage({ senderType: 'ROLE_CLIENT', message: content });

            // return new Promise<void>((resolve, reject) => {
            //     setTimeout(() => {
            //         notifications.sendCustomEvent('msg', {
            //             message: encodeHTML(content),
            //             receiverId: this.performerId,
            //             receiverType: 'ROLE_PERFORMER'
            //         });
            //         resolve();
            //     }, 50);
            // });
        }
    }
});
