export interface SeoText {
    id: number;
    description: string;
    image_location: string;
    image_position: string;
    image_title: string;
    order: number;
    title: string;
}

export interface CategoryData {
    id: number;
    language: string;
    slug: string;
    meta_description: string;
    meta_keywords: string;
    meta_title: string;
    mainText?: SeoText;
    texts: SeoText[];
}

export interface Promo {
    country: string;
    endDate: number;
    id: number;
    language: string;
    position: number;
    startDate: number;
    status: string;
    text1: string;
    text2: string;
}

export function transformCategoryData(data: CategoryData): CategoryData {
    data.mainText = data.texts.shift();

    return data;
}
