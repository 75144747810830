import { defineStore } from 'pinia';

export const useModalsStore = defineStore('modals', {
    state: (): Partial<any> => ({
        modals: [
            { name: 'login', active: false },
            { name: 'register', active: false },
            { name: 'lostpassword', active: false },
            { name: 'resetpassword', active: false },
            { name: 'new', active: false },
            { name: 'cookie18', active: false },
            { name: 'loader', active: false },
            { name: 'close', active: false, next: undefined },
            { name: 'unlock', active: false },
            { name: 'payment', active: false },
            { name: 'paysafe', active: false }
        ]
    }),
    actions: {
        getModal(name: string) {
            const modal = this.modals.find((modal: any) => modal.name === name);
            return this.modals.find((modal: any) => modal.name === name);
        },
        async toggleModal(name: string, boolean?: boolean, next?: any) {
            this.closeAllModals();
            const modal = this.modals.find((modal: any) => modal.name === name);

            if (typeof boolean != 'undefined') {
                modal.active = boolean;
            } else {
                modal.active = !modal.active;
            }

            if (next) {
                modal.next = next;
            }
        },
        async closeModal(name: string) {
            const modal = this.modals.find((modal: any) => modal.name === name);
            modal.active = false;
            if (modal.next) {
                modal.next = undefined;
            }
        },
        async closeAllModals() {
            this.modals.map((modal: any) => (modal.active = false));
        }
    },
    getters: {
        hasActiveModals(state: any) {
            return state.modals ? state.modals.some((modal: any) => modal.active === true) : false;
        },
        getActiveModal(state: any) {
            return state.modals.find((modal: any) => modal.active === true) ? state.modals.find((modal: any) => modal.active === true).name : '';
        }
    }
});
