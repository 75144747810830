import type { Response } from '../response';
import fetch from 'isomorphic-fetch';

import { baseApi } from '..';
import type { Location } from '@/ontology/i18n';

//tells the server what our locale is.. result is discarded
export default async function (): Promise<Response<Location>> {
    const fullUrl: string = `${baseApi}/loc`;
    const response = await fetch(fullUrl);

    if (!response.ok) {
        return {
            error: { code: response.status, message: response.statusText }
        };
    }

    return { result: await response.json() };
}
